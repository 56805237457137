import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import HeroCard from './Card'
import { Container, Section } from '@components/common'

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "images/stock/h001.jpeg" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Section
      verticalSpacing="none"
      backgroundImage={data.image.childImageSharp.fluid}
    >
      <Container>
        <div className="lg:w-1/2 lg:mx-0 -mx-6 lg:mt-0 lg:py-gap-xl">
          <HeroCard />
          <Img
            fluid={data.image.childImageSharp.fluid}
            className="lg:hidden block"
          />
        </div>
      </Container>
    </Section>
  )
}
export default Hero
