import React from 'react'
import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'
import LogoGrid from './LogoGrid'

const ClientsSection = () => {
  return (
    <Section>
      <Container>
        <h2 className="mb-gap-xl">
          Join the growing number of businesses working with us
        </h2>
        <LogoGrid />

        <div className="mt-gap-large">
          <p>
            <Button appearance="link" link="/clients">
              Learn more about our clients
            </Button>
          </p>
        </div>
      </Container>
    </Section>
  )
}
export default ClientsSection
