import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Section } from '@components/common'
import { Button } from '@mrs-ui'
import Img from 'gatsby-image'

const MicrosoftPartnerSection = () => {
  const data = useStaticQuery(graphql`
    query {
      analyticsImage: file(
        relativePath: { eq: "images/logos/partners/ms_gold_logo-main.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Section verticalSpacing="xl">
        <Container>
          <div className="flex flex-wrap -mx-10">
            <div className="md:w-1/2 w-full px-10  mb-4">
              <Img
                fluid={data.analyticsImage.childImageSharp.fluid}
                style={{ minHeight: '360px' }}
              />
            </div>
            <div className="md:w-1/2 w-full px-10 mb-4">
              <h2 className="mb-gap-compact">
                Transform with MRS + Microsoft Cloud
              </h2>
              <p className="mb-gap-compact">
                Unleash the full potential of the Microsoft Cloud and deliver
                business results faster with our end-to-end Microsoft cloud
                services.
              </p>
              <p className="mb-gap-compact">
                We're certified partners for Cloud Platform (Azure), Collaboration
                and Content (SharePoint and Office 365), Messaging (Microsoft
                Teams) and more.
              </p>
              <p>
                <Button
                  appearance="link"
                  link="/lp/sharepoint-consulting"
                  className="mr-gap-mini"
                >
                  View our SharePoint services
                </Button>
                <Button
                  appearance="link"
                  link="/lp/power-bi-services"
                  className="mr-gap-mini"
                >
                  View our Power BI services
                </Button>
              </p>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}
export default MicrosoftPartnerSection
