import {
  ClientsSection,
  CTA,
  Hero,
  MainSection,
  MicrosoftPartnerSection,
} from '@components/pages/home'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          url
          description
        }
      }
    }
  `)
  const Head = () => {
    return (
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title="MRS Company | Cloud. Data. Experts."
        meta={[
          {
            name: `description`,
            content: data.site.siteMetadata.description,
          },
          {
            property: `og:title`,
            content: 'MRS Company | Cloud. Data. Experts.',
          },
          {
            property: `og:description`,
            content: data.site.siteMetadata.description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: 'MRS Company | Cloud. Data. Experts.',
          },
          {
            name: `twitter:description`,
            content: data.site.siteMetadata.description,
          },
          {
            name: `og:image`,
            content: `${data.site.siteMetadata.url}/og/og-img-mrs.jpg`,
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
        ]}
      />
    )
  }
  return (
    <>
      <Head />
      <Hero />
      <MainSection />
      <MicrosoftPartnerSection />
      <ClientsSection />

      <CTA />
    </>
  )
}

export default IndexPage
