import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import './styles.scss'

const LogoGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      logos: allFile(
        filter: { relativeDirectory: { eq: "images/logos/clients" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxHeight: 54, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)

  return (
    <div className="hp__client-logo-grid">
      {data.logos.edges.map(({ node }) => (
        <div className="hp__client-logo-grid__item" key={node.id}>
          <Img
            fluid={node.childImageSharp.fluid}
            className="hp__client-logo-grid__img"
            objectFit="contain"
            objectPosition="left"
          />
        </div>
      ))}
    </div>
  )
}
export default LogoGrid
