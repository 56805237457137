import React from 'react'
import ContentFeature from '@components/ContentFeature'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Section } from '@components/common'
import { Icon } from '@mrs-ui'

const MainSection = () => {
  const data = useStaticQuery(graphql`
    query {
      analyticsImage: file(
        relativePath: {
          eq: "images/stock/it-software-consulting-toronto-58.jpeg"
        }
      ) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cloudImage: file(relativePath: { eq: "images/stock/h022.webp" }) {
        childImageSharp {
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Section verticalSpacing="xl">
        <Container>
          <h2 className="mb-gap-default">
            We're here to help you make the most of your IT
          </h2>
          <div className="flex flex-wrap -mx-5">
            <div className="md:w-1/2 w-full px-5 mb-gap-mini">
              <ContentFeature
                image={data.analyticsImage.childImageSharp.fluid}
                title="40+ years of proven professionalism"
                excerpt={
                  <>
                    From SMB to enterprise, public to private sector and across
                    industries, organizations have counted on us to deliver
                    mission-critical IT services and solutions for over four
                    decades. Since our inception, we've been driven by our belief
                    in the power and potential of software for every business.
                  </>
                }
                link="/about"
                linkText="Learn more about MRS"
              />
            </div>
            <div className="md:w-1/2 w-full px-5 mb-gap-mini">
              <ContentFeature
                image={data.cloudImage.childImageSharp.fluid}
                title="Services and expertise to help your business thrive"
                excerpt="Accelerate data-driven innovation and harness untapped potential. Modernize your business with cloud transformation. Enhance productivity and improve the employee experience. MRS helps organizations envision, execute and deliver results-oriented digital technology solutions."
                link="/services"
                linkText="Learn more about our services"
              />
            </div>
          </div>
        </Container>
      </Section>
      {/* <Section>
        <Container>
          <div className="flex -mx-8">
            <div className="w-1/3 px-8">
              <ContentFeature
                icon={<Icon name="Info" />}
                title="About us"
                excerpt="Learn more about MRS and see why you can trust us to help your business succeed."
                link="/about"
                linkText="Learn more about MRS"
              />
            </div>
            <div className="w-1/3 px-8">
              <ContentFeature
                icon={<Icon name="Users" />}
                title="Partners & Certifications"
                excerpt="View our strategic partnerships with industry-leading technology providers."
                link="/partners"
                linkText="View our Partners"
              />
            </div>
            <div className="w-1/3 px-8">
              <ContentFeature
                icon={<Icon name="FileText" />}
                title="Blog"
                excerpt="See the latest updates, announcements and insights from our blog."
                link="/blog"
                linkText="View our blog"
              />
            </div>
          </div>
        </Container>
      </Section> */}
    </>
  )
}
export default MainSection
