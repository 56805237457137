import React, { useState, useEffect } from 'react'

import TextTransition, { presets } from 'react-text-transition'
import { Button } from '@mrs-ui'

const TEXTS = [
  'cloud solutions.',
  'Microsoft 365.',
  'data analytics.',
  'SharePoint.',
  'Power BI.',
  'IT services.',
  'custom software.',
]

const HeroCard = () => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => setIndex(index => index + 1), 2200)
    return () => clearTimeout(intervalId)
  }, [])
  return (
    <div className="md:p-gap-large p-gap-mini bg-base">
      <div>
        <div className="mb-gap-compact">
          <h1>
            Your trusted partners for
            <span style={{ color: 'var(--primary-color)' }}>
              {' '}
              <TextTransition
                text={TEXTS[index % TEXTS.length]}
                springConfig={presets.default}
                inline
              />
            </span>
          </h1>
        </div>
        <div className="mb-gap-compact">
          <p className="text-large">
            Conquer IT challenges and accelerate your digital transformation with
            a technology partner you can trust.
          </p>
        </div>
        <div>
          <Button
            link="/contact"
            appearance="primary"
            className="mr-gap-mini mb-gap-mini md:mb-0"
          >
            Get started today
          </Button>

          <Button link="/client-success" appearance="link">
            View client success
          </Button>
        </div>
      </div>
    </div>
  )
}
export default HeroCard
